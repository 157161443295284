import { Link, Route, Routes, Navigate } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import Home  from './pages/Home';
import ObjInfo from './pages/objInfo';
import ObjSearch from './pages/objSearch';
import NotFound from './pages/NotFound';
import About from './pages/About';
// import BarLoader from "react-spinners/BarLoader";


function App() {

  // const [loading, setLoading] = useState(false);

  // useEffect( () => {
  //   setLoading(true)
  //   setTimeout(()=>{
  //     setLoading(false)
  //   },1000)
  // },[])

  return (
    
    <>
     {

      // loading ? 
      // <div className="loadingCont">
      //   <BarLoader
      //     color={'#000000'}
      //     loading={loading}
      //     size={150}

      //   />
      // </div>
      
      // :
      
      <Routes>
        <Route path='/' element={<Home/>}/>

        <Route path='/uno' element={ <Navigate to="/obj/1" /> }/>


        <Route path='/obj' element={<ObjSearch/>} />
        <Route path='/obj'>
          <Route path=':id' element={<ObjInfo/>}/>
          <Route path='*' element={<ObjSearch/>}/>
        </Route>

        <Route path='/about' element={<About/>}/>
        
        <Route path='/*' element={<NotFound/>}/>
      </Routes>

     }
      
      
    </>
  )
}

export default App;
