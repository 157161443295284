import { Navigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import OBJdb from "../objects.json"
import '../objCataloge.css'
import SearchBar from '../SearchBar';
import Etiqueta from '../etiqueta';

    const ObjInfo = () => {
         
        const {id} = useParams()
        const gallery = OBJdb[id]['extraImgs'] ? OBJdb[id]['extraImgs'] : false
        console.log(gallery)
        
    if(!OBJdb[id]){
        window.location.replace("/obj");
    }else{
        return (
            <>
            
            <SearchBar/>

            <div className="mainContainer">
                <div className="subContainer">
                <Etiqueta/>
                    <img src={OBJdb[id]['img']} alt="" className="heroImg"/>
                    <div className="infoCont">
                        <div className="objName">{OBJdb[id]['name']}</div>
                        <div className="objBy">{OBJdb[id]['by'] ? OBJdb[id]['by'] : null}</div>
                        <div className="objDesc">{OBJdb[id]['desc']}</div>  
                        {/* <div className="gallery">
                            {
                                gallery 
                                ?
                                gallery.map( (img) => (
                                    <img src={img} alt="" className="heroImg"/>
                                ))
                                :
                                <br/>
                            }
                        </div> */}
                    </div>
                </div>       
            </div>
           </>
        );
    }

}
 
export default ObjInfo;