const About = () => {

    return (
        <div className="about">
            <h2>About</h2>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis rem libero vel, ut placeat saepe soluta, magnam reprehenderit consectetur nesciunt consequuntur suscipit impedit odio nulla temporibus magni velit mollitia odit!</p>
        </div>
    );
}
 
export default About;