import { Link } from 'react-router-dom';

const Etiqueta = () => {
    return (  
      <div className="etiquetaCont"><img src="../img/vvvvvvvv_co.png" alt="" className="etiqueta" /></div>

    );
}
 
export default Etiqueta;

