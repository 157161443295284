import { Link } from 'react-router-dom';

const SearchBar = () => {
    return (  
        <div className="objNav">
            <div className="objSearch">
                <Link to="/about" >?</Link>
            </div>
            <div className="objSearch">
                <Link to="/obj" >Search</Link>
            </div>
        </div>

    );
}
 
export default SearchBar;

