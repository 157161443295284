import { useNavigate } from "react-router-dom";
import SearchBar from '../SearchBar';
import '../objCataloge.css';


const ObjSearch = () => {
    
    const navigate = useNavigate()
    
    const handleClick = () => {
        const lookFor = document.getElementById("lookFor").value
        navigate('/obj/' + lookFor)
    }

    const handleEnter = (e) => {
        if(e.keyCode == 13){
            console.log(window.document)
            // handleClick()
        }
    }





    
    return (
        <>
       <SearchBar/>
        <p>search</p>
        <input type="number" name="" id="lookFor" onKeyDown={handleEnter} />
        <button onClick={handleClick}>Search</button>
       </>
    );
}
 
export default ObjSearch;