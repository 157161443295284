const Home = () => {

    return (
        <div className="home">
            <h2>Home</h2>
            <p>{Math.random()}</p>
        </div>
    );
}
 
export default Home;